import { select, all, takeEvery, call } from 'redux-saga/effects'
import { notification } from 'antd'
// import { history } from 'App'
import * as firebase from 'services/firebase'
// import moment from 'moment'
// import { rsFirebase, fireBase } from '../../firebase'
import { rsFirebase } from '../../firebase'
// import { USER, PATH } from 'constant'
import actions from './actions'

const mapAuthProviders = {
  firebase: {
    reorderVOD: firebase.reorderList,
    copyVOD: firebase.copyVOD,
  },
}

export function* DELETE({ payload }) {
  const { id } = payload
  yield call(rsFirebase.firestore.deleteDocument, `vod/${id}`)

  notification.success({
    message: '삭제 완료.',
    description: 'VOD가 삭제되었습니다.',
    duration: 1,
  })
}

export function* ADD({ payload }) {
  const {
    order,
    subject,
    description,
    streamingUrl,
    thumbnailUrl,
    thumbnailPath,
    part,
    speaker,
    speakerTitle,
    open,
    isPlayable,
    purpose,
    forceSyncTime,
    playerController,
    scheduleId,
    isABR,
  } = payload

  const data = {
    order,
    subject,
    description: description || '',
    streamingUrl: streamingUrl || '',
    thumbnailUrl: thumbnailUrl || null,
    thumbnailPath: thumbnailPath || null,
    part: part || null,
    speaker: speaker ?? '',
    speakerTitle: speakerTitle ?? '',
    open: open || false,
    video: 'vod',
    isPlayable: isPlayable || false,
    purpose: purpose || 'vod',
    forceSyncTime: forceSyncTime === undefined ? false : forceSyncTime,
    playerController: playerController === undefined ? true : playerController,
    scheduleId: scheduleId || null,
    isABR: isABR || false,
  }
  console.log(data)

  yield call(rsFirebase.firestore.addDocument, 'vod', data)
  notification.success({
    message: '등록 완료.',
    description: 'VOD가 등록되었습니다.',
    duration: 1,
  })
}

export function* UPDATE({ payload }) {
  const {
    id,
    subject,
    description,
    streamingUrl,
    thumbnailUrl,
    thumbnailPath,
    part,
    speaker,
    speakerTitle,
    open,
    isPlayable,
    purpose,
    forceSyncTime,
    playerController,
    scheduleId,
    isABR,
  } = payload

  const data = {
    id,
    subject,
    description: description || '',
    streamingUrl: streamingUrl || '',
    thumbnailUrl: thumbnailUrl || null,
    thumbnailPath: thumbnailPath || null,
    part: part || null,
    speaker: speaker || '',
    speakerTitle: speakerTitle || '',
    open: open || false,
    isPlayable: isPlayable || false,
    purpose: purpose || 'vod',
    forceSyncTime: forceSyncTime === undefined ? false : forceSyncTime,
    playerController: playerController === undefined ? true : playerController,
    scheduleId: scheduleId || null,
    isABR: isABR || false,
  }

  yield call(rsFirebase.firestore.setDocument, `vod/${id}`, data, { merge: true })

  notification.success({
    message: '수정 완료.',
    description: 'VOD가 수정되었습니다.',
    duration: 1,
  })
}

export function* OPEN({ payload }) {
  const open = !payload.open
  yield call(rsFirebase.firestore.setDocument, `vod/${payload.id}`, { open: open }, { merge: true })
}

export function* SET_PLAYABLE({ payload }) {
  const isPlayable = !payload.isPlayable
  yield call(
    rsFirebase.firestore.setDocument,
    `vod/${payload.id}`,
    { isPlayable: isPlayable },
    { merge: true },
  )
}
export function* SET_SUBTITLE({ payload }) {
  yield call(
    rsFirebase.firestore.setDocument,
    `vod/${payload.id}`,
    { subtitles: payload.subtitles },
    { merge: true },
  )
}

export function* ORDER({ payload }) {
  const { authProvider: authProviderName } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProviderName].reorderVOD, payload, 'vod')
}

export function* COPY({ payload }) {
  const { authProvider: authProviderName } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProviderName].copyVOD, payload)
  notification.success({
    message: '복사 완료.',
    description: 'VOD가 복사되었습니다.',
    duration: 1,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD, ADD),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.OPEN, OPEN),
    takeEvery(actions.SET_PLAYABLE, SET_PLAYABLE),
    takeEvery(actions.SET_SUBTITLE, SET_SUBTITLE),
    takeEvery(actions.ORDER, ORDER),
    takeEvery(actions.COPY, COPY),
    // takeEvery(actions.ACTIVATE, ACTIVATE),
    // takeEvery(actions.CURRENT, CURRENT),
    // takeEvery(actions.LIVE_STATE, LIVE_STATE),
  ])
}
