import React from 'react'
import { useDispatch } from 'react-redux'
// import { Link } from 'react-router-dom'

// eslint-disable-next-line no-unused-vars
import { FormattedMessage } from 'react-intl'
// import { Button } frsom 'antd'
// import { Badge } from 'antd'
// import PATH from 'path.config'
// import { TEXT } from 'constant'
import style from './style.module.scss'
import ScheduleDropdownSelector from '../../../Schedule/ScheduleDropdownSelector'

const TopBar = () => {
  const dispatch = useDispatch()
  // console.log(sessions)
  // eslint-disable-next-line no-unused-vars
  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  return (
    <div className={`${style.topbar}`}>
      <div className="w-100">
        <ScheduleDropdownSelector />
      </div>
      {/* <div className="mb-0 ml-auto ml-md-0 font-size-16">
        <Link to="/mypage" className={`${style.highlight} d-md-inline d-none`}>
          내 정보
        </Link>
        <span className={`${style.highlight} d-md-inline d-none`}> | </span>
        <a href="#" onClick={logout} className={`${style.highlight} d-md-inline d-none`}>
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
        <a href="#" onClick={logout} className={`${style.highlight} d-inline d-md-none`}>
          <i className="fe fe-log-out" />
        </a>
      </div> */}
    </div>
  )
}

export default TopBar
