// eslint-disable-next-line no-unused-vars
import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import * as firebase from 'services/firebase'
import { rsFirebase } from '../../firebase'
import actions from './actions'

const mapAuthProviders = {
  firebase: {
    reorderSchedule: firebase.reorderList,
    getSchedule: firebase.getSchedule,
  },
}

const documentName = 'schedules'

export function* GET() {
  const { authProvider } = yield select(state => state.settings)

  try {
    const response = yield call(mapAuthProviders[authProvider].getUserProfileField)
    yield put({
      type: 'schedule/SET_STATE',
      payload: {
        loading: false,
        title: response.title,
        date: response.date,
      },
    })
  } catch (e) {
    console.log(e)
  }
}

export function* ADD({ payload }) {
  const data = {
    ...payload,
  }

  const docRef = yield call(rsFirebase.firestore.addDocument, documentName, data)
  const idData = {
    id: docRef.id,
  }
  yield call(rsFirebase.firestore.setDocument, docRef, idData, { merge: true })
  notification.success({
    message: '등록 완료.',
    description: '일정이 등록되었습니다.',
    duration: 1,
  })
}

export function* DELETE({ payload }) {
  const { id } = payload
  yield call(rsFirebase.firestore.deleteDocument, `${documentName}/${id}`)

  notification.success({
    message: '삭제 완료.',
    description: '일정이 삭제되었습니다.',
    duration: 1,
  })
}

export function* UPDATE({ payload }) {
  console.log(payload)

  const fieldId = payload.id
  const data = {
    id: payload.id,
    ...payload,
  }

  yield call(rsFirebase.firestore.setDocument, `${documentName}/${fieldId}`, data, { merge: true })

  notification.success({
    message: '수정 완료.',
    description: '일정이 수정되었습니다.',
    duration: 1,
  })
}

export function* ORDER({ payload }) {
  const { authProvider: authProviderName } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProviderName].reorderSchedule, payload, 'schedules')
}

export function* SET_CHANNEL({ payload }) {
  console.log(payload)

  const fieldId = payload.id
  const channel = payload.channel.id
  const { liveStream } = payload
  const data = {
    channel,
    liveStream,
  }

  yield call(rsFirebase.firestore.setDocument, `${documentName}/${fieldId}`, data, { merge: true })

  notification.success({
    message: '채널 할당 완료.',
    description: '채널이 새로 할당되었습니다..',
    duration: 1,
  })
}

export function* SET_SURVEY({ payload }) {
  console.log(payload)

  const fieldId = payload.id
  const { surveyId } = payload
  const data = {
    surveyId,
  }

  yield call(rsFirebase.firestore.setDocument, `${documentName}/${fieldId}`, data, { merge: true })

  notification.success({
    message: '설문조사 할당 완료.',
    description: '설문조사가 새로 할당되었습니다..',
    duration: 1,
  })
}

export function* SET_SCHEDULE({ payload }) {
  yield put({
    type: 'schedule/SET_STATE',
    payload: {
      schedule: payload.schedule,
    },
  })
}

export function* UPDATE_SCHEDULE({ payload }) {
  // console.log(payload)

  const fieldId = payload.schedule.id
  const data = {
    ...payload.data,
  }

  yield call(rsFirebase.firestore.setDocument, `${documentName}/${fieldId}`, data, { merge: true })

  notification.success({
    message: '수정 완료.',
    description: '일정이 수정되었습니다.',
    duration: 1,
  })
}

export function* GET_SCHEDULE({ payload }) {
  const fieldId = payload.id
  // yield call(rsFirebase.firestore.setDocument, `${documentName}/${fieldId}`, data, { merge: true })

  const successActionCreator = doc => {
    // console.log(doc)
    let schedule = {}
    if (doc && doc.exists) {
      schedule = doc.data()
    }

    // console.log('successActionCreator')
    return {
      type: actions.SET_STATE,
      payload: { schedule: schedule },
    }
  }

  yield call(rsFirebase.firestore.syncDocument, `${documentName}/${fieldId}`, {
    successActionCreator,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD, ADD),
    takeEvery(actions.GET, GET),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.ORDER, ORDER),
    takeEvery(actions.SET_CHANNEL, SET_CHANNEL),
    takeEvery(actions.SET_SCHEDULE, SET_SCHEDULE),
    takeEvery(actions.UPDATE_SCHEDULE, UPDATE_SCHEDULE),
    takeEvery(actions.GET_SCHEDULE, GET_SCHEDULE),
    takeEvery(actions.SET_SURVEY, SET_SURVEY),
  ])
}
