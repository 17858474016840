/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'
import { Button, Dropdown, Menu, Row, Col } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import moment from 'moment'
import * as _ from 'lodash'
import style from './style.module.scss'

const ScheduleDropdownSelector = ({ onChangeSchedule }) => {
  const dispatch = useDispatch()
  const selectedSchedule = useSelector(state => state.schedule.schedule)

  useFirestoreConnect({
    collection: 'schedules',
    orderBy: ['order'],
  })
  const schedules = useSelector(state => state.firestore.ordered.schedules)
  const scheduleMap = useSelector(state => state.firestore.data.schedules)

  // console.log(schedules)
  // useEffect(() => {
  //   if (!selectedSchedule && schedules && schedules.length > 0) {
  //     setSelectedSchedule(schedules[0])
  //   }
  // }, [schedules, selectedSchedule])

  const onSelectSchedule = item => {
    dispatch({
      type: 'schedule/GET_SCHEDULE',
      payload: {
        id: item.key,
      },
    })
  }

  // console.log(schedules)

  const renderScheduleMenu = () => {
    return (
      <Menu onClick={onSelectSchedule}>
        {schedules &&
          schedules.map(schedule => {
            return (
              <Menu.Item key={schedule.id} className={`${style.menu}`}>
                <div className={`${style.menuItem}`}>
                  <p className={`${style.menuTitle}`}>{schedule.title}</p>
                  <p className={`${style.menuDate}`}>
                    {moment(schedule.date * 1000).format('YYYY년 MM월 DD일 ')}(
                    {moment(schedule.startAt * 1000).format('HH:mm')} -{' '}
                    {moment(schedule.endAt * 1000).format('HH:mm')})
                  </p>
                </div>
              </Menu.Item>
            )
          })}
      </Menu>
    )
  }

  return (
    <Dropdown overlay={renderScheduleMenu()} className={`${style.dropdown}`}>
      <Button className={`${style.dropdownButton}`}>
        {selectedSchedule ? (
          <Row justify="space-around" align="middle">
            <Col flex="auto">
              <p className={`${style.buttonTitle}`}>{selectedSchedule.title}</p>
              <p className={`${style.buttonDate}`}>
                {moment(selectedSchedule.date * 1000).format('YYYY년 MM월 DD일 ')}(
                {moment(selectedSchedule.startAt * 1000).format('HH:mm')} -{' '}
                {moment(selectedSchedule.endAt * 1000).format('HH:mm')})
              </p>
            </Col>
            <Col flex="50px">
              <DownOutlined />
            </Col>
          </Row>
        ) : (
          <Row justify="space-around" align="middle">
            <Col flex="auto">
              <div className={`${style.selectScheduleButton}`}>
                <span>프로그램 일정 선택</span>
              </div>
            </Col>
            <Col flex="50px">
              <DownOutlined />
            </Col>
          </Row>
        )}{' '}
      </Button>
    </Dropdown>
  )
}

// export default QnA
export default ScheduleDropdownSelector
