const actions = {
  SET_STATE: 'chat/SET_STATE',
  CHAT_GET_MESSAGES: 'chat/GET_MESSAGES',
  CHAT_UPDATE_CHATROOM: 'chat/UPDATE_CHATROOM',
  CHAT_SEND_MESSAGE: 'chat/SEND_MESSAGE',
  NEW_MESSAGE_SUCCESFULL: 'chat/NEW_MESSAGE_SUCCESFULL',
  TOGGLE_HIGHLIGHT: 'chat/TOGGLE_HIGHLIGHT',
  CHAT_DELETE_MESSAGE: 'chat/DELETE_MESSAGE',
  CHAT_TRUNCATE_ALL: 'chat/TRUNCATE_ALL',
}

export default actions
