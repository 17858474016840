import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'App'
import * as firebase from 'services/firebase'
import { USER } from 'constant'
import PATH from 'path.config'
import actions from './actions'

const mapAuthProviders = {
  firebase: {
    login: firebase.login,
    register: firebase.register,
    currentAccount: firebase.currentAccount,
    logout: firebase.logout,
    updateUser: firebase.updateUser,
    deleteUser: firebase.deleteUser,
    sendFindPasswordMail: firebase.sendFindPasswordMail,
    sendBeacon: firebase.sendBeacon,
    moveUserToSchedule: firebase.moveUserToSchedule,
    deleteMultipleUsers: firebase.deleteMultipleUsers,
  },
}

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].login, email, password)
  // console.log(success);

  if (success) {
    try {
      yield put({
        type: 'user/LOAD_CURRENT_ACCOUNT',
      })
      yield history.push(PATH.ADMIN.HOME)
      notification.success({
        message: '로그인 성공',
        description: '로그인 되었습니다.',
        duration: 1,
      })
    } catch (error) {
      console.log('LOGIN saga', error)
    }
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* REGISTER({ payload }) {
  const { email, password, ...data } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const success = yield call(
    mapAuthProviders[authProvider].register,
    email,
    password,
    data,
    // name,
    // nickname,
    // phone,
    // department,
    // work,
    // attendance,
    // type,
    // role,
  )

  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield history.push(PATH.AUTH.REGISTER_COMPLETE)
    notification.success({
      message: '접수 완료.',
      description: '컨퍼런스 등록 신청이 접수되었습니다.',
      duration: 2,
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* USER_EDIT({ payload }) {
  // console.log('USER_EDIT', payload)
  // const { email, name, nickname, phone, department, work, attendance, type, role } = payload
  console.log(payload)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  // console.log(mapAuthProviders[authProvider].updateUser)

  const success = yield call(mapAuthProviders[authProvider].updateUser, payload)

  if (success) {
    notification.success({
      message: '수정 완료.',
      description: '회원 정보가 수정되었습니다.',
      duration: 1,
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* USER_DELETE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  // console.log(mapAuthProviders[authProvider].updateUser)
  const success = yield call(mapAuthProviders[authProvider].deleteUser, payload)
  // console.log(success)
  if (success) {
    notification.success({
      message: '삭제 완료.',
      description: '참가자가 삭제되었습니다.',
      duration: 1,
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)

  try {
    const response = yield call(mapAuthProviders[authProvider].currentAccount)
    if (response) {
      // const { id, name, nickname, phone, department, work, attendance, type, role, email } = response
      const { id, role, email } = response
      // console.log(role)
      if (role === USER.TYPE.ADMIN) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            id,
            // name,
            // nickname,
            // phone,
            // department,
            // work,
            // attendance,
            // type,
            role,
            email,
            authorized: true,
          },
        })
      } else {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            authorized: false,
          },
        })
        throw new Error('User is not admin role')
      }
    }
  } catch (error) {
    notification.error({
      message: '로그인 실패',
      description: '관리자 계정만 로그인이 가능합니다.',
      duration: 3,
    })
    // console.log('LOAD_CURRENT_ACCOUNT', error)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  const { authProvider } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProvider].logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      // name: '',
      // nickname: '',
      // phone: '',
      // department: '',
      // work: '',
      // attendance: '',
      // type: '',
      role: '',
      email: '',
      authorized: false,
      loading: false,
    },
  })
}

export function* FORGOT_PASSWORD({ payload }) {
  const { authProvider } = yield select(state => state.settings)
  const result = yield call(mapAuthProviders[authProvider].sendFindPasswordMail, payload.email)
  // console.log(result)
  if (result) {
    yield history.push(PATH.AUTH_LOGIN)
    notification.success({
      message: '메일 보내기 성공',
      description:
        '비밀번호 재설정 메일이 전송되었습니다. 메일을 확인하신 후 비밀번호를 재설정 해주세요. ',
      duration: 3,
    })
  }
}

// eslint-disable-next-line require-yield
export function* MOVE_TO_SCHEDULE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      userListLoading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProvider].moveUserToSchedule, payload)

  yield put({
    type: 'user/SET_STATE',
    payload: {
      userListLoading: false,
    },
  })
  // results
  // console.log("🚀 ~ file: sagas.js ~ line 280 ~ function*MOVE_TO_SCHEDULE ~ result", result)
}

export function* DELETE_MULTIPLE_USERS({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      userListLoading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProvider].deleteMultipleUsers, payload)

  yield put({
    type: 'user/SET_STATE',
    payload: {
      userListLoading: false,
    },
  })
  // results
  // console.log("🚀 ~ file: sagas.js ~ line 280 ~ function*MOVE_TO_SCHEDULE ~ result", result)
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.USER_DELETE, USER_DELETE),
    takeEvery(actions.USER_EDIT, USER_EDIT),
    takeEvery(actions.FORGOT_PASSWORD, FORGOT_PASSWORD),
    takeEvery(actions.MOVE_TO_SCHEDULE, MOVE_TO_SCHEDULE),
    takeEvery(actions.DELETE_MULTIPLE_USERS, DELETE_MULTIPLE_USERS),

    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
