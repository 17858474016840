const PATH = {
  HOME: '/',
  SIGNUP: '/auth/signup',

  ADMIN: {
    HOME: '/admin',
    DASHBOARD: '/admin/dashboard',
    SITE_SETTINGS: '/admin/site/settings',
    SITE_USER_AUTH: '/admin/site/user-auth',
    SITE_WELCOME_MAIL: '/admin/site/welcome-mail',
    DIRECTOR: '/admin/director',
    CHAT: '/admin/chat',
    CHANNEL: '/admin/channel',

    SCHEDULE: '/admin/schedule',
    LIVE: '/admin/live',
    VOD: '/admin/vod',
    LOG_REPORT: '/admin/logreport',

    SURVEY_LIST: '/admin/survey/list',
    SURVEY_ADD: '/admin/survey/add',
    SURVEY_EDIT: '/admin/survey/edit',
    // SURVEY_ITEM: '/admin/make-survey',
    SURVEY_REPORT: '/admin/survey/report',

    USERS: '/admin/users',
    USER_FIELDS: '/admin/userFields',

    BROADCAST: '/admin/broadcast',
    CATEGORY: '/admin/category',
    STREAMING: '/admin/streaming',
  },
  POPUP: {
    QNA: '/popup/admin/qna',
  },

  AUTH: {
    LOGIN: '/auth/login',
    REGISTER: '/auth/register',
    REGISTER_SHORT: '/r',
    FORGOT_PASSWORD: '/auth/forgot-password',
    REGISTER_COMPLETE: '/register/complete',
    LOGOUT: '/auth/logout',
  },

  SYSTEM_404: '/auth/404',
}

export default PATH
