import React, { lazy, Suspense } from 'react'

// import firebaseConfig from './firebase/'
import { ConnectedRouter } from 'connected-react-router'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import PATH from 'path.config'

// import UserList from './pages/Admin/User/List'
// import Director from './pages/Admin/Director'
// import QnAList from './pages/Admin/QnA'
// import Dashboard from './pages/Admin/Dashboard'
// import SessionList from './pages/Admin/Live/List'
// import VodList from './pages/Admin/VOD/List'

// import RegisterCompletion from './components/Conference/Register'

// import Login from './components/Auth/Login'
// // import Logout from './components/Auth/Logout'
// import Signup from './components/Auth/Signup'
// import ForgotPassword from './components/Auth/ForgotPassword'
// import System404 from './pages/404'

// import Base from './components/'
// import PrivateRoute from '../PrivateRoute';
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import Layout from './layouts'

const routes = [
  // Dashboards
  {
    path: PATH.ADMIN.HOME,
    key: PATH.ADMIN.HOME,
    Component: lazy(() => import('./pages/Admin/Home')),
    exact: true,
  },
  {
    path: PATH.ADMIN.SITE_SETTINGS,
    key: PATH.ADMIN.SITE_SETTINGS,
    Component: lazy(() => import('./pages/Admin/Project/Site')),
    exact: true,
  },
  {
    path: PATH.ADMIN.SITE_USER_AUTH,
    key: PATH.ADMIN.SITE_USER_AUTH,
    Component: lazy(() => import('./pages/Admin/Project/User')),
    exact: true,
  },
  {
    path: PATH.ADMIN.SITE_WELCOME_MAIL,
    key: PATH.ADMIN.SITE_WELCOME_MAIL,
    Component: lazy(() => import('./pages/Admin/Project/WelcomeMail')),
    exact: true,
  },
  {
    path: PATH.ADMIN.USERS,
    key: PATH.ADMIN.USERS,
    Component: lazy(() => import('./pages/Admin/User/List')),
    exact: true,
  },
  {
    path: PATH.ADMIN.DASHBOARD,
    key: PATH.ADMIN.DASHBOARD,
    Component: lazy(() => import('./pages/Admin/Dashboard')),
    exact: true,
  },
  {
    path: PATH.ADMIN.USER_FIELDS,
    key: PATH.ADMIN.USER_FIELDS,
    Component: lazy(() => import('./pages/Admin/User/Fields')),
    exact: true,
  },
  {
    path: PATH.ADMIN.SCHEDULE,
    key: PATH.ADMIN.SCHEDULE,
    Component: lazy(() => import('./pages/Admin/Schedule')),
    exact: true,
  },
  {
    path: PATH.ADMIN.LIVE,
    key: PATH.ADMIN.LIVE,
    Component: lazy(() => import('./pages/Admin/Live/List')),
    exact: true,
  },
  {
    path: PATH.ADMIN.VOD,
    key: PATH.ADMIN.VOD,
    Component: lazy(() => import('./pages/Admin/VOD/List')),
    exact: true,
  },
  {
    path: PATH.ADMIN.LOG_REPORT,
    key: PATH.ADMIN.LOG_REPORT,
    Component: lazy(() => import('./pages/Admin/LogReport')),
    exact: true,
  },
  {
    path: PATH.ADMIN.DIRECTOR,
    key: PATH.ADMIN.DIRECTOR,
    Component: lazy(() => import('./pages/Admin/Director')),
    exact: true,
  },
  {
    path: PATH.ADMIN.CHAT,
    key: PATH.ADMIN.CHAT,
    Component: lazy(() => import('./pages/Admin/Chat')),
    exact: true,
  },
  {
    path: PATH.ADMIN.CHANNEL,
    key: PATH.ADMIN.CHANNEL,
    Component: lazy(() => import('./pages/Admin/Channel')),
    exact: true,
  },
  {
    path: PATH.ADMIN.SURVEY_LIST,
    key: PATH.ADMIN.SURVEY_LIST,
    Component: lazy(() => import('./pages/Admin/Survey/List')),
    exact: true,
  },
  {
    path: `${PATH.ADMIN.SURVEY_ADD}/:id?`,
    key: PATH.ADMIN.SURVEY_ADD,
    Component: lazy(() => import('./pages/Admin/Survey/Form')),
    exact: true,
  },
  {
    path: `${PATH.ADMIN.SURVEY_EDIT}/:id`,
    key: PATH.ADMIN.SURVEY_EDIT,
    Component: lazy(() => import('./pages/Admin/Survey/Form')),
    exact: false,
  },
  {
    path: `${PATH.ADMIN.SURVEY_REPORT}/:id`,
    key: PATH.ADMIN.SURVEY_REPORT,
    Component: lazy(() => import('./pages/Admin/Survey/Report')),
    exact: true,
  },

  {
    path: PATH.AUTH.LOGIN,
    key: PATH.AUTH.LOGIN,
    Component: lazy(() => import('./components/Auth/Login')),
    exact: true,
  },
  // {
  //   path: PATH.AUTH.REGISTER,
  //   key: PATH.AUTH.REGISTER,
  //   Component: lazy(() => import('./components/Auth/Signup')),
  //   exact: true,
  // },
  // {
  //   path: PATH.AUTH.FORGOT_PASSWORD,
  //   key: PATH.AUTH.FORGOT_PASSWORD,
  //   Component: lazy(() => import('./components/Auth/ForgotPassword')),
  //   exact: true,
  // },
  // {
  //   path: PATH.AUTH.REGISTER_COMPLETE,
  //   key: PATH.AUTH.REGISTER_COMPLETE,
  //   Component: lazy(() => import('./components/Conference/Register')),
  //   exact: true,
  // },
  // {
  //   path: PATH.AUTH.REGISTER_COMPLETE,
  //   key: PATH.AUTH.REGISTER_COMPLETE,
  //   Component: lazy(() => import('./components/Conference/Register')),
  //   exact: true,
  // },
  {
    path: PATH.AUTH.LOGOUT,
    key: PATH.AUTH.LOGOUT,
    Component: lazy(() => import('./components/Auth/Logout')),
    exact: true,
  },
  {
    path: PATH.SYSTEM_404,
    key: PATH.SYSTEM_404,
    Component: lazy(() => import('./pages/404')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
  // routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state

            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/admin" />} />
                    {routes.map(({ path, Component, exact, key }) => {
                      // console.log(key)
                      return (
                        <Route
                          path={path}
                          key={key}
                          exact={exact}
                          render={() => {
                            return (
                              <div className={routerAnimation}>
                                <Suspense fallback={null}>
                                  <Component />
                                </Suspense>
                              </div>
                            )
                          }}
                        />
                      )
                    })}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
