/* eslint-disable no-unused-vars */
import { all, takeEvery, put, call, select } from 'redux-saga/effects'
// import * as firebase from 'services/firebase'
import { getLogList } from 'services/firebase'
import * as firebase from 'services/firebase'
import { rsFirebase, fireBase, fireStore } from '../../firebase'
import actions from './actions'

// eslint-disable-next-line require-yield
export function* ADD_LOG_GROUP({ payload }) {
  const documentRef = 'logs'
  const { title, active } = payload
  const data = {
    title,
    active: active || false,
    createdAt: fireBase.firestore.Timestamp.now(),
  }
  yield call(rsFirebase.firestore.addDocument, documentRef, data)
}
// eslint-disable-next-line require-yield
export function* DELETE_LOG_GROUP({ payload }) {
  console.log(payload.log.id)
  const documentRef = `logs/${payload.log.id}`
  yield call(rsFirebase.firestore.deleteDocument, documentRef)
}

export function* ACTIVATE({ payload }) {
  const { id, active } = payload
  const documentRef = `logs/${id}`
  const data = {
    active: active || false,
  }
  yield call(rsFirebase.firestore.setDocument, documentRef, data, { merge: true })
}

export function* GET_LIVE_LOGS() {
  console.log('get live logs')
  const logList = yield call(getLogList)

  yield put({
    type: 'logger/SET_STATE',
    payload: {
      logs: logList,
    },
  })
}

export function* RECORD() {
  const success = yield call(firebase.logRecord)
}

export default function* rootSaga() {
  yield all([takeEvery(actions.ADD_LOG_GROUP, ADD_LOG_GROUP)])
  yield all([takeEvery(actions.DELETE_LOG_GROUP, DELETE_LOG_GROUP)])
  yield all([takeEvery(actions.ACTIVATE, ACTIVATE)])
  yield all([takeEvery(actions.RECORD, RECORD)])
  // yield all([takeEvery(actions.DELETE_LOG_GROUP, DELETE_LOG_GROUP)])
}
