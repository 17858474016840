const actions = {
  SET_STATE: 'userProfileField/SET_STATE',
  ADD: 'userProfileField/ADD',
  GET: 'userProfileField/GET',
  DELETE: 'userProfileField/DELETE',
  UPDATE: 'userProfileField/UPDATE',
  ORDER: 'userProfileField/ORDER',
}

export default actions
