import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
// import { history } from 'App'
// import * as firebase from 'services/firebase'

// import moment from 'moment'
// import { rsFirebase, fireBase } from '../../firebase'
import { rsFirebase } from '../../firebase'
// import { USER, PATH } from 'constant'
import actions from './actions'

export function* DELETE({ payload }) {
  const { id } = payload
  yield put({
    type: 'channel/SET_STATE',
    payload: {
      loading: true,
    },
  })
  yield call(rsFirebase.firestore.deleteDocument, `channels/${id}`)

  notification.success({
    message: '삭제 완료.',
    description: '채널이 삭제되었습니다.',
    duration: 1,
  })
  yield put({
    type: 'channel/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE({ payload }) {
  console.log(payload)

  const { channelName, rtmpURL, streamKey, abrURL } = payload

  yield put({
    type: 'channel/SET_STATE',
    payload: {
      loading: true,
    },
  })

  let channelId
  if (!payload.id || payload.id === '') {
    channelId = channelName
  } else {
    channelId = payload.id
  }

  const data = {
    id: channelId,
    channelName,
    rtmpURL,
    streamKey,
    abrURL,
  }

  yield call(rsFirebase.firestore.setDocument, `channels/${channelId}`, data, { merge: true })

  notification.success({
    message: '저장 완료.',
    description: '채널이 저장되었습니다.',
    duration: 1,
  })
  yield put({
    type: 'channel/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_LIST({ payload }) {
  yield put({
    type: 'channel/SET_STATE',
    payload: {
      loading: true,
    },
  })
  console.log(payload)
  yield put({
    type: 'channel/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_DATA({ payload }) {
  yield put({
    type: 'channel/SET_STATE',
    payload: {
      loading: true,
    },
  })
  console.log(payload)
  yield put({
    type: 'channel/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    // takeEvery(actions.ADD, ADD),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.UPDATE, UPDATE),
    // takeEvery(actions.GET_LIST, GET_LIST),
    // takeEvery(actions.GET_DATA, GET_DATA),
    // takeEvery(actions.ENABLE_QNA, ENABLE_QNA),
    // takeEvery(actions.ACTIVATE, ACTIVATE),
    // takeEvery(actions.CURRENT, CURRENT),
    // takeEvery(actions.LIVE_STATE, LIVE_STATE),
  ])
}
