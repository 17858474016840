const actions = {
  SET_STATE: 'session/SET_STATE',
  GET_DATA: 'session/GET_DATA',
  GET_LIST: 'session/GET_LIST',
  ADD: 'session/ADD',
  UPDATE: 'session/UPDATE',
  DELETE: 'session/DELETE',
  ACTIVATE: 'session/ACTIVATE',
  ENABLE_QNA: 'session/ENABLE_QNA',
  CURRENT: 'session/CURRENT',
  REGIST_QUESTION: 'session/REGIST_QUESTION',
  HIGHLIGHT_QUESTION: 'session/HIGHLIGHT_QUESTION',
  LIVE_STATE: 'session/LIVE_STATE',
  ORDER: 'session/ORDER',
}

export default actions
