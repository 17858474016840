const actions = {
  SET_STATE: 'schedule/SET_STATE',
  ADD: 'schedule/ADD',
  GET: 'schedule/GET',
  DELETE: 'schedule/DELETE',
  UPDATE: 'schedule/UPDATE',
  ORDER: 'schedule/ORDER',
  SET_CHANNEL: 'schedule/SET_CHANNEL',
  SET_SCHEDULE: 'schedule/SET_SCHEDULE',
  UPDATE_SCHEDULE: 'schedule/UPDATE_SCHEDULE',
  GET_SCHEDULE: 'schedule/GET_SCHEDULE',
  SET_SURVEY: 'schedule/SET_SURVEY',
}

export default actions
