const actions = {
  ADD: 'survey/ADD',
  UPDATE: 'survey/UPDATE',
  DELETE: 'survey/DELETE',
  ORDER: 'survey/ORDER',
  DELETE_REPORT: 'survey/DELETE_REPORT',
  ADD_LIST: 'survey/ADD_LIST',
  UPDATE_LIST: 'survey/UPDATE_LIST',
  DELETE_LIST: 'survey/DELETE_LIST',
  TOGGLE_OPEN: 'survey/TOGGLE_OPEN',
}

export default actions
