import React from 'react'
import { TEXT } from 'constant'
import style from './style.module.scss'

const Footer = () => {
  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        <span className={`${style.logo} mr-1`}>{TEXT.TITLE.TITLE}</span>
        <br />
        <p className={`${style.copyright} mb-0`}>{TEXT.COPYRIGHT}</p>
      </div>
    </div>
  )
}

export default Footer
