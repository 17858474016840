const actions = {
  SET_STATE: 'logger/SET_STATE',
  ADD_LOG_GROUP: 'logger/ADD_LOG_GROUP',
  GET_LOGS: 'logger/GET_LOGS',
  DELETE_LOG_GROUP: 'logger/DELETE_LOG_GROUP',
  ACTIVATE: 'logger/ACTIVATE',
  RECORD: 'logger/RECORD',
}

export default actions
