import actions from './actions'

const initialState = {
  id: '',
  name: '',
  // nickname: '',
  phone: '',
  department: '',
  work: '',
  // attendance: '',
  type: '',
  role: '',
  email: '',
  authorized: false, // false is default value
  loading: false,
  userListLoading: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
