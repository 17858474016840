import React from 'react'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import { withRouter, Link } from 'react-router-dom'
// import { Link, withRouter } from 'react-router-dom'
import { TEXT } from 'constant'
import classNames from 'classnames'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  logo: settings.logo,
  isGrayTopbar: settings.isGrayTopbar,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  authPagesColor: settings.authPagesColor,
})

const AuthLayout = ({
  children,
  // logo,
  // isGrayTopbar,
  // isCardShadow,
  isSquaredBorders,
  isBorderless,
  authPagesColor,
}) => {
  return (
    <Layout>
      <Layout.Content>
        <div
          className={classNames(`${style.container}`, {
            cui__layout__squaredBorders: isSquaredBorders,
            // cui__layout__cardsShadow: isCardShadow,
            cui__layout__borderless: isBorderless,
            [style.white]: authPagesColor === 'white',
            [style.gray]: authPagesColor === 'gray',
          })}
          style={{
            backgroundImage:
              authPagesColor === 'image' ? 'url(resources/images/content/photos/7.jpg)' : '',
          }}
        >
          <div className={classNames(`${style.topbar}`)}>
            <div className={style.logoContainer}>
              <Link to="/">
                <div className={style.logo}>
                  {/* <span className={style.highlight}>{TEXT.TITLE}</span> */}
                  <span className={style.desc}>{TEXT.DESCRIPTION}</span>
                  <span className={style.name}>{TEXT.TITLE}</span>
                </div>
              </Link>
            </div>
            <div className="d-sm-block">
              {/* <Link to="/auth/register" className={`font-size-16 ${style.link}`}>
                참가 신청 하기
              </Link> */}
            </div>
          </div>
          <div className={style.containerInner}>{children}</div>
          <div className={`${style.bottombar} mt-auto pb-5 pt-5`}>
            <div className={`${style.bottomText}`}>{TEXT.COPYRIGHT}</div>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default withRouter(connect(mapStateToProps)(AuthLayout))
