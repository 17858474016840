import { notification } from 'antd'
// import { useSelector } from 'react-redux'
// import { useFirestoreConnect } from 'react-redux-firebase'
// import { useFirestore } from 'react-defux-firebase'
// import axios from 'axios'
import moment from 'moment'
import { USER } from 'constant'
import { firebaseAuth, fireBase, fireStore, firebaseFunctions } from '../../firebase'

// const REGION = 'asia-northeast3';
// import admin from '../../firebase/admin'
if (process.env.NODE_ENV === 'development') {
  // firebaseFunctions.useFunctionsEmulator('http://localhost:5001')
}
export async function login(email, password) {
  return firebaseAuth
    .signInWithEmailAndPassword(email, password)
    .then(response => {
      const { user } = response
      // console.log(user)
      userLog(user, 'login')
      return response
    })
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
        duration: 2,
      })
    })
}

// eslint-disable-next-line no-unused-vars
export async function userLog(user, type) {
  // let data = {}
  // const now = moment().format('YYYY-MM-DD HH:mm:ss')
  // if (type === 'login') {
  //   data = {
  //     loggedAt: now,
  //   }
  // } else if (type === 'logout') {
  //   data = {
  //     loggOutActionAt: now,
  //   }
  // }
  // fireStore
  //   .collection('users')
  //   .doc(user.uid)
  //   .set(data, {
  //     merge: true,
  //   })
  //   .then(() => true)
  //   .catch(error => {
  //     console.log(error)
  //     return false
  //   })
  // return true
}
// eslint-disable-next-line no-unused-vars
export async function sendBeacon(path = 'unknown') {
  // const user = firebaseAuth.currentUser
  // const nowID = moment().format('YYYY-MM-DD_HH:mm:ss')
  // let beacon = {}
  // beacon[nowID] = path
  // fireStore
  //   .collection('users')
  //   .doc(`${user.uid}`)
  //   .set(
  //     { beacon: beacon },
  //     {
  //       merge: true,
  //     },
  //   )
  //   .then(() => true)
  //   .catch(error => {
  //     console.log(error)
  //     return false
  //   })
}

export async function register(
  email,
  password,
  payload,

  // email,
  // password,
  // name,
  // nickname,
  // phone,
  // department,
  // work,
  // attendance,
  // type,
) {
  return firebaseAuth
    .createUserWithEmailAndPassword(email, password)
    .then(response => {
      if (response.user) {
        const now = moment().format('YYYY-MM-DD HH:mm:ss')
        const { uid } = response.user
        const data = {
          ...payload,
          role: 'user',
          email,
          // phone,
          // name,
          // nickname,
          // department,
          // work,
          attendance: 'online',
          type: 'listener',
          registeredAt: now,
        }
        // console.log(data)
        fireStore
          .collection('users')
          .doc(uid)
          .set(data)
          .then(res => {
            console.log(res)
            return true
          })
      }
      return true
    })
    .catch(error => {
      let { message } = error
      switch (error.code) {
        case 'auth/claims-too-large':
          message = '하루 가입 제한을 넘었습니다'
          break
        case 'auth/email-already-exists':
        case 'auth/email-already-in-use':
          message = ' 이미 존재하는 이메일입니다'
          break
        case 'auth/invalid-email':
          message = '잘못된 이메일 형식입니다'
          break
        case 'auth/weak-password':
          message = '비밀번호는 6자리 이상으로 사용해 주세요'
          break
        default:
          break
      }
      notification.warning({
        // message: error.code,
        message: '참가신청 오류',
        description: message,
        duration: 2,
      })
    })
}

export async function currentAccount() {
  let userLoaded = false
  function getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(firebaseAuth.currentUser)
      }
      const unsubscribe = auth.onAuthStateChanged(user => {
        userLoaded = true
        unsubscribe()
        const getUserData = async () => {
          if (user) {
            // console.log(user.uid)

            const userRef = await fireStore.collection('users').doc(user.uid)

            await userRef.get().then(profile => {
              const userFields = profile.data()
              // console.log(userFields)
              if (!userFields) {
                return user
              }
              const mergedUser = Object.assign(user, {
                id: user.uid,
                name: userFields.name,
                // nickname: userFields.nickname,
                phone: userFields.phone,
                department: userFields.department,
                work: userFields.work,
                attendance: userFields.attendance,
                type: userFields.type,
                role: userFields.role,
                // email: user.email,
              })
              // // console.log(mergedUser)
              return mergedUser
            })
          }
          return user
        }
        resolve(getUserData())
      }, reject)
    })
  }
  return getCurrentUser(firebaseAuth)
}

export async function logout() {
  const user = firebaseAuth.currentUser
  // console.log(user)
  await userLog(user, 'logout')

  return firebaseAuth.signOut().then(() => true)
}

export async function sendFindPasswordMail(emailAddress) {
  return firebaseAuth
    .sendPasswordResetEmail(emailAddress)
    .then(() => {
      // Email sent.
      return true
    })
    .catch(error => {
      // An error happened.
      console.log(error)
    })
}

export async function updateUser(data) {
  if (firebaseAuth.currentUser.role === USER.TYPE.ADMIN) {
    fireStore
      .collection('users')
      .doc(data.id)
      .set(data.values, {
        merge: true,
      })
      .then(() => true)
      .catch(error => {
        console.log(error)
        return false
      })
    return true
  }
  return false
}

export async function deleteUser(data) {
  const deleteUserData = async () => {
    let result = false
    if (firebaseAuth.currentUser.role === USER.TYPE.ADMIN) {
      const deleteUserFunc = firebaseFunctions.httpsCallable('deleteUser')
      await deleteUserFunc(data).then(response => {
        // console.log(response)
        result = response
      })
    }
    return result
  }
  return deleteUserData()
}

export async function deleteMultipleUsers(users) {
  const deleteUserData = async () => {
    let result = false
    if (firebaseAuth.currentUser.role === USER.TYPE.ADMIN) {
      firebaseFunctions.useFunctionsEmulator('http://localhost:5001')
      const deleteUserFunc = firebaseFunctions.httpsCallable('deleteMultipleUsers')
      await deleteUserFunc(users).then(response => {
        // console.log(response)
        result = response
      })
    }
    return result
  }
  return deleteUserData()
}

export async function deactivate() {
  await fireStore
    .collection('sessions')
    .where('active', '==', true)
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        if (doc.exists) {
          // console.log(doc.data())
          doc.ref.update({ active: false })
          // doc.set({ active: false }, {
          //   merge: true,
          // })
        }
      })
    })
  return true
}

export async function currentSession() {
  const getSessionData = async () => {
    const sessionRef = await fireStore.collection('sessions').where('active', '==', true)
    let session = null
    await sessionRef.get().then(snapshot => {
      snapshot.forEach(doc => {
        if (doc.exists) {
          session = doc.data()
          // console.log(doc)
          session.id = doc.id
          return true
        }
        return false
      })
    })
    return session
  }
  return getSessionData()
}

export async function getLogList() {
  console.log('service getLogList')
  const logs = []
  await fireStore
    .collectionGroup('userLiveLogs')
    .get()
    .then(list => {
      list.forEach(item => {
        // console.log(item.data())s
        logs.push(item.data())
      })
    })
  // console.log(logs)
  return logs
}

export async function getUserProfileField() {
  const sessionRef = await fireStore.collection('userProfileField').orderBy('order', 'asc')
  let userProfileFields = []
  await sessionRef.get().then(snapshot => {
    snapshot.forEach(doc => {
      if (doc.exists) {
        const data = doc.data()
        data.id = doc.id
        userProfileFields.push(data)
        return true
      }
      return false
    })
  })
  return userProfileFields
}

export async function reorderUserField(orderedField) {
  if (firebaseAuth.currentUser.role === USER.TYPE.ADMIN) {
    const userFieldRef = fireStore.collection('userProfileField')
    const batch = fireStore.batch()
    const originalDoc = userFieldRef.doc(orderedField.original.id)
    batch.update(originalDoc, {
      order: orderedField.target.order,
    })
    const targetDoc = userFieldRef.doc(orderedField.target.id)
    batch.update(targetDoc, {
      order: orderedField.original.order,
    })
    batch.commit().then(result => {
      return result
    })

    return true
  }
  return false
}

export async function getSchedule() {
  const scheduleRef = await fireStore.collection('schedules').orderBy('order', 'asc')
  let schedules = []
  await scheduleRef.get().then(snapshot => {
    snapshot.forEach(doc => {
      if (doc.exists) {
        const data = doc.data()
        data.id = doc.id
        schedules.push(data)
        return true
      }
      return false
    })
  })
  return schedules
}

export async function reorderList(list, collection) {
  if (firebaseAuth.currentUser.role === USER.TYPE.ADMIN) {
    const ref = fireStore.collection(collection)
    const batch = fireStore.batch()
    list.forEach((item, index) => {
      const doc = ref.doc(item.id)
      batch.update(doc, {
        order: index,
      })
    })
    batch.commit().then(result => {
      return result
    })

    return true
  }
  return false
}

export async function deleteDocument(collection, doc) {
  console.log(collection, doc)
  if (firebaseAuth.currentUser.role === USER.TYPE.ADMIN) {
    fireStore
      .collection(collection)
      .doc(doc)
      .delete()
      .then(() => true)
      .catch(error => {
        console.log(error)
        return false
      })
    return true
  }
  return false
}

export async function functionCall(functionName, data) {
  const callFunction = async () => {
    let result = false
    if (firebaseAuth.currentUser.role === USER.TYPE.ADMIN) {
      const func = firebaseFunctions.httpsCallable(functionName)
      await func(data).then(response => {
        // console.log(response)
        result = response
      })
    }
    return result
  }
  return callFunction()
}

export async function logRecord() {
  const onlineUsers = []
  await fireStore
    .collection('users')
    .where('state', '==', 'online')
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        if (doc.exists) {
          onlineUsers.push(doc.id)
        }
      })
    })

  await fireStore
    .collection('logs')
    .where('active', '==', true)
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        if (doc.exists) {
          const now = fireBase.firestore.Timestamp.now()
          console.log('insert log', onlineUsers)
          fireStore
            .collection('logs')
            .doc(doc.id)
            .collection('online')
            .doc(`online-${now.seconds}`)
            .set({ users: onlineUsers, createdAt: now })
        }
      })
    })
}

export async function copyVOD(payload) {
  // console.log(payload)

  const vods = []
  await fireStore
    .collection('vod')
    .where('scheduleId', '==', payload.sourceScheduleId)
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        if (doc.exists) {
          const data = doc.data()
          data.scheduleId = payload.targetScheduleId
          data.originalId = doc.id
          vods.push(data)
        }
      })
    })

  // console.log(vods)
  const batch = fireStore.batch()
  vods.forEach(vod => {
    const userFieldRef = fireStore
      .collection('vod')
      .doc(`${vod.originalId}_to_${payload.targetScheduleId}_copy`)
    batch.set(userFieldRef, vod)
  })
  batch.commit().then(result => {
    return result
  })
}

export async function moveUserToSchedule(payload) {
  console.log(payload)
  const { users, targetScheduleId } = payload

  const batch = fireStore.batch()
  try {
    users.forEach(async user => {
      const userFieldRef = fireStore.collection('users').doc(user)
      batch.set(
        userFieldRef,
        { defaultSchedule: targetScheduleId },
        {
          merge: true,
        },
      )
    })
  } catch (_e) {
    console.log('🚀 [n:491]~ _e:', _e)
  }
  try {
    await batch.commit().then(result => {
      // console.log('🚀 ~ result:', result)
      return result
    })
  } catch (_e) {
    console.log('batch update error', _e)
  }
}
