const actions = {
  SET_STATE: 'vod/SET_STATE',
  ADD: 'vod/ADD',
  UPDATE: 'vod/UPDATE',
  DELETE: 'vod/DELETE',
  ACTIVATE: 'vod/ACTIVATE',
  ENABLE_QNA: 'vod/ENABLE_QNA',
  CURRENT: 'vod/CURRENT',
  OPEN: 'vod/OPEN',
  SET_PLAYABLE: 'vod/SET_PLAYABLE',
  SET_SUBTITLE: 'vod/SET_SUBTITLE',
  ORDER: 'vod/ORDER',
  COPY: 'vod/COPY',
}

export default actions
