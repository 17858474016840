import PATH from 'path.config'

export default async function getMenuData() {
  return [
    {
      category: true,
      title: 'Dashboards',
    },
    {
      title: '홈',
      key: 'home',
      icon: 'fe fe-home',
      url: PATH.ADMIN.HOME,
    },
    {
      title: '사이트 설정',
      key: 'sitesettings',
      // url: PATH.GUIDE,
      children: [
        {
          title: '사이트 설정',
          key: 'project_settings',
          url: PATH.ADMIN.SITE_SETTINGS,
        },
        {
          title: '회원 권한 설정',
          key: 'project_user',
          url: PATH.ADMIN.SITE_USER_AUTH,
        },
        {
          title: '가입 환영 이메일 설정',
          key: 'project_welcome_mail',
          url: PATH.ADMIN.SITE_WELCOME_MAIL,
        },
      ],
    },
    {
      title: '프로그램 일정',
      key: 'schedule',
      icon: 'fe fe-calendar',
      url: PATH.ADMIN.SCHEDULE,
    },

    {
      category: true,
      title: '프로그램',
    },
    {
      title: '프로그램 진행',
      key: 'dashboards',
      icon: 'fe fe-sliders',
      url: PATH.ADMIN.DASHBOARD,
    },

    {
      title: '라이브 진행',
      key: 'progress',
      icon: 'budicon budicon-media-player-page font-weight-bold',
      url: PATH.ADMIN.DIRECTOR,
    },
    {
      title: '채팅관리',
      key: 'chat',
      icon: 'budicon budicon-typing-chat font-weight-bold',
      url: PATH.ADMIN.CHAT,
    },
    // {
    //   title: '채팅관리',
    //   key: 'chat',
    //   icon: 'fe fe-mic',
    //   url: PATH.ADMIN.CHAT,
    // },

    {
      category: true,
      title: '스트리밍/VOD 관리',
    },
    // {
    //   title: '카테고리 관리',
    //   key: 'category',
    //   icon: 'fe fe-list',
    //   url: PATH.ADMIN.CATEGORY,
    // },

    {
      title: '라이브 프로그램 목록',
      key: 'live',
      icon: 'fe fe-list',
      url: PATH.ADMIN.LIVE,
    },
    {
      title: 'VOD 목록',
      key: 'vod',
      icon: 'fe fe-youtube',
      url: PATH.ADMIN.VOD,
    },
    {
      category: true,
      title: '보고서',
    },
    {
      title: '온라인 로그 관리',
      key: 'view_report',
      icon: 'budicon budicon-presentation-slide-a font-weight-bold',
      url: PATH.ADMIN.LOG_REPORT,
    },

    {
      category: true,
      title: '설문조사',
    },
    {
      title: '설문조사 목록',
      key: 'survey_list',
      icon: 'budicon budicon-business-checklist font-weight-bold',
      url: PATH.ADMIN.SURVEY_LIST,
    },
    {
      category: true,
      title: '방송관리',
    },
    // {
    //   title: '방송설정',
    //   key: 'streaming',
    //   icon: 'fe fe-video',
    //   url: PATH.ADMIN.BROADCAST,
    // },
    {
      title: '채널관리',
      key: 'channel',
      icon: 'budicon budicon-television font-weight-bold',
      url: PATH.ADMIN.CHANNEL,
    },
    {
      category: true,
      title: '회원관리',
    },
    {
      title: '회원목록',
      key: 'users',
      icon: 'budicon budicon-user-list-a font-weight-bold',
      url: PATH.ADMIN.USERS,
    },
    {
      title: '회원 가입 항목',
      key: 'user_fields',
      icon: 'budicon budicon-user-plus-a font-weight-bold',
      url: PATH.ADMIN.USER_FIELDS,
    },

    {
      category: true,
      title: '관리자',
    },
    {
      title: '로그아웃',
      key: 'logout',
      icon: 'budicon budicon-power-sign font-weight-bold',
      url: PATH.AUTH.LOGOUT,
    },
  ]
}
